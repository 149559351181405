import consumer from "../channels/consumer";

// Broadcast Types
const JOIN_ROOM = "JOIN_ROOM";
const EXCHANGE = "EXCHANGE";
const REMOVE_USER = "REMOVE_USER";

// DOM Elements
let currentUser;
let localVideo;
let remoteVideoContainer;

// Objects
let pcPeers = {};
let localstream;

window.onload = () => {
  currentUser = document.querySelector("meta[name='userId']").getAttribute('content');
  localVideo = document.getElementById("local-video");
  remoteVideoContainer = document.getElementById("remote-video-container");
};

// Ice Credentials
const ice = { iceServers: [{ urls: "stun:stun.l.google.com:19302" }] };

// Add event listener's to buttons
document.addEventListener("turbolinks:load", () => {
  const workoutVideo = document.getElementById("workoutVideo");
  if (workoutVideo !== null) {
    const joinButton = document.getElementById("join-button");
    const leaveButton = document.getElementById("leave-button");

    joinButton.onclick = handleJoinSession;
    leaveButton.onclick = handleLeaveSession;
  }
});

// Initialize user's own video
// document.onreadystatechange = () => initVideo();
// document.addEventListener("turbolinks:load", () => initVideo());

const initVideo = (cb) => {
  const workoutVideo = document.getElementById("workoutVideo");
  if (workoutVideo !== null) {
    navigator.mediaDevices
    .getUserMedia({
      audio: true,
      video: true,
    })
    .then((stream) => {
      localstream = stream;
      localVideo.srcObject = stream;
      localVideo.muted = true;

      document.getElementById('videoFrameLocal').classList.remove('visually-hidden');

      if (cb) return cb();
    })
    .catch(logError);
  }
}

const handleJoinSession = async () => {
  await initVideo(() => {
    consumer.subscriptions.create("SessionChannel", {
      connected: () => {
        broadcastData({
          type: JOIN_ROOM,
          from: currentUser,
        });
      },
      received: (data) => {
        console.log("received", data);
        if (data.from === currentUser) return;
        switch (data.type) {
        case JOIN_ROOM:
          return joinRoom(data);
        case EXCHANGE:
          if (data.to !== currentUser) return;
          return exchange(data);
        case REMOVE_USER:
          return removeUser(data);
        default:
          return;
        }
      },
    });
  });
};

const handleLeaveSession = () => {
  for (let user in pcPeers) {
    pcPeers[user].close();
  }
  pcPeers = {};

  remoteVideoContainer.innerHTML = "";

  toggleButtons('disconnecting');

  broadcastData({
    type: REMOVE_USER,
    from: currentUser,
  });
};

const joinRoom = (data) => {
  createPC(data.from, true);
};

const removeUser = (data) => {
  console.log("removing user", data.from);
  document.getElementById('workoutVideo').dataset.connected = false;
  let video = document.getElementById(`remoteVideoContainer+${data.from}`);
  video && video.remove();
  delete pcPeers[data.from];
};

const createPC = (userId, isOffer) => {
  let pc = new RTCPeerConnection(ice);
  const element = document.createElement("video");
  element.id = `remoteVideoContainer+${userId}`;
  element.autoplay = "autoplay";
  element.playsInline = true;
  remoteVideoContainer.appendChild(element);

  document.getElementById('workoutVideo').dataset.connected = true;
  toggleButtons('connecting');

  pcPeers[userId] = pc;

  for (const track of localstream.getTracks()) {
    pc.addTrack(track, localstream);
  }

  isOffer &&
    pc
      .createOffer()
      .then((offer) => {
        return pc.setLocalDescription(offer);
      })
      .then(() => {
        broadcastData({
          type: EXCHANGE,
          from: currentUser,
          to: userId,
          sdp: JSON.stringify(pc.localDescription),
        });
      })
      .catch(logError);

  pc.onicecandidate = (event) => {
    event.candidate &&
      broadcastData({
        type: EXCHANGE,
        from: currentUser,
        to: userId,
        candidate: JSON.stringify(event.candidate),
      });
  };

  pc.ontrack = (event) => {
    if (event.streams && event.streams[0]) {
      element.srcObject = event.streams[0];
    } else {
      let inboundStream = new MediaStream(event.track);
      element.srcObject = inboundStream;
    }
  };

  pc.oniceconnectionstatechange = () => {
    if (pc.iceConnectionState == "disconnected") {
      console.log("Disconnected:", userId);
      broadcastData({
        type: REMOVE_USER,
        from: userId,
      });
    }
  };

  return pc;
};

const exchange = (data) => {
  let pc;

  if (!pcPeers[data.from]) {
    pc = createPC(data.from, false);
  } else {
    pc = pcPeers[data.from];
  }

  if (data.candidate) {
    pc.addIceCandidate(new RTCIceCandidate(JSON.parse(data.candidate)))
      .then(() => console.log("Ice candidate added"))
      .catch(logError);
  }

  if (data.sdp) {
    const sdp = JSON.parse(data.sdp);
    pc.setRemoteDescription(new RTCSessionDescription(sdp))
      .then(() => {
        if (sdp.type === "offer") {
          pc.createAnswer()
            .then((answer) => {
              return pc.setLocalDescription(answer);
            })
            .then(() => {
              broadcastData({
                type: EXCHANGE,
                from: currentUser,
                to: data.from,
                sdp: JSON.stringify(pc.localDescription),
              });
            });
        }
      })
      .catch(logError);
  }
};

const broadcastData = (data) => {
  /**
   * Add CSRF protection: https://stackoverflow.com/questions/8503447/rails-how-to-add-csrf-protection-to-forms-created-in-javascript
   */
  const csrfToken = document.querySelector("[name=csrf-token]").content;
  const headers = new Headers({
    "content-type": "application/json",
    "X-CSRF-TOKEN": csrfToken,
  });

  fetch("/sessions", {
    method: "POST",
    body: JSON.stringify(data),
    headers,
  });
};

const logError = (error) => console.warn("Whoops! Error:", error);

const toggleButtons = (state) => {
  const joinButton = document.getElementById("join-button");
  const leaveButton = document.getElementById("leave-button");

  if (state === 'connecting') {
    joinButton.parentElement.classList.add("visually-hidden");
    leaveButton.parentElement.classList.remove("visually-hidden");
  } else {
    joinButton.parentElement.classList.remove("visually-hidden");
    leaveButton.parentElement.classList.add("visually-hidden");
  }
}
