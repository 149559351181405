import Rails from "@rails/ujs";

let inputTimeout;

document.addEventListener("turbolinks:load", function() {
  var role = document.querySelector("meta[name='userRole']").getAttribute('content');

  document.addEventListener("trix-change", function(event) {
    clearTimeout(inputTimeout);
    inputTimeout = setTimeout(saveReport, 500);
  })

  if (!document.getElementById('workoutUrl')) return;

  var workoutUrl = document.getElementById('workoutUrl').innerText;

  function saveReport() {
    if (!document.querySelector("input[name='workout[report]']")) return;

    var report = document.querySelector("input[name='workout[report]']").value;
    var data = { report: report };

    fetch(workoutUrl, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
      var status = document.getElementById('reportStatus');
      status.style.display='inline-block';
      setTimeout(function(){
        status.style.display='none';
      }, 1200);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }
});
