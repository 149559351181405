import consumer from "./consumer"

consumer.subscriptions.create("WorkoutChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log('connected to websocket');
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    console.log('disconnected from websocket');
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    console.log('received data', data);

    var role = document.querySelector("meta[name='userRole']").getAttribute('content');
    if (role === 'trainer') return;

    if (data.event === 'updatedReport') {
      const element = document.getElementById('clientReport');
      element.innerHTML = data.report;
    }
  }
});
